<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <!-- Prime User -->
        <div class="d-flex overall-count">
          <div class="mr-2 pb-2">Overall User</div>
          <Switches
              v-model="overall"
              type-bold="true"
              color="success"
              class="m-0"
          ></Switches>
        </div>
        <!-- Prime User End -->
      </div>
      <div class="d-flex justify-content-end">
        <JsonExcel
            class=""
            :data="registrants"
            name="registrants.csv"
            type="csv">
          <div class="btn pr-0"><i class="mdi mdi-download"></i> Download</div>
        </JsonExcel>
      </div>

      <div class="table-responsive">
        <b-table
            :items="registrants"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
        >
          <template v-slot:cell(visited_during_session)="row">
            <i
                class="fas fa-check-circle"
                style="color: #a4a4a4"
                v-if="row.item.visited_during_session !== 1"
            ></i>
            <i class="fas fa-check-circle" style="color: #1cbb8c" v-else></i>
          </template>

          <template v-slot:cell(utm_params)="row">
            <template v-for="(u,i) in JSON.parse(row.item.utm_params)">{{i}} : {{u}}, </template>
          </template>

        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_pa ginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.ri-mail-send-line:hover {
  color: #1cbb8c;
}
</style>
<script>
import Vue from "vue";
import appConfig from "@/app.config";
import filterMixins from "../../../mixins/filterData";
import moment from "moment";
import JsonExcel from "vue-json-excel";
import VueToast from "vue-toast-notification";
import Switches from "vue-switches";
import "vue-toast-notification/dist/theme-sugar.css";
Vue.use(VueToast);
export default {
  props: ["filters"],
  mixins: [filterMixins],
  components: {
    Switches,
    JsonExcel
  },
  name : 'registrants table',
  data() {
    return {
      registrants: [],
      overall: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 50],
      filter: null,
      filterOn: [],
      sortBy: "total_views",
      sortDesc: true,
      doctorName: "",
      mobileNumber: null,
      fields: [
        { key: "title", sortable: true, label: "Live Event" },
        { key: "doctor", sortable: true, label: "Doctor" },
        { key: "mobile_number", sortable: true, label: "Mobile No." },
        { key: "email", sortable: true, label: "Email" },
        { key: "speciality", sortable: true, label: "Speciality" },
        { key: "city", sortable: true, label: "City" },
        { key: "state", sortable: true, label: "State" },
        { key: "country", sortable: true, label: "Country" },
        { key: "registered_at", sortable: true, label: "Registered At" },
        { key: "visited_during_session", sortable: true, label: "Is Visited" },
        { key: "utm_params", sortable: true, label: "Utm Params" },
      ],
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getRegistrations();
      },
    },
    overall() {
      this.getRegistrations();
    },
    // search() {
    //   this.getUsersList();
    // },
  },
  computed: {
    rows() {
      return this.registrants.length;
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getRegistrations() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        if (this.overall === true) {
          if(searchfiltersParams.live_event_ids.length > 0){
            const response = await this.$http.get(
                appConfig.api_base_url + "/liveEvents/members?overall=true",
                {
                  params: searchfiltersParams,
                },
            );
            this.registrants = response.data.members;
            this.totalRows = this.registrants.length;
          }else{
            alert("Please Select Atleast One Live Event");
            this.overall = false;
          }
        } else {
          // let params = Object.keys(this.filters).length > 0 ? this.filters : {};
          const response = await this.$http.get(
              appConfig.api_base_url + "/liveEvents/members",
              {
                params: searchfiltersParams,
              }
          );
          this.registrants = response.data.members;
          this.totalRows = this.registrants.length;
        }
      } catch (err) {
        console.log(err);
      }
    },
    getFormatedDate(date) {
      try {
        if (date != null) {
          return moment(date).format("yyyy-MM-DD");
        }
        return null;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
  },
  mounted() {
    this.getRegistrations();
  },
};
</script>