import { render, staticRenderFns } from "./registrants.vue?vue&type=template&id=376411d4&"
import script from "./registrants.vue?vue&type=script&lang=js&"
export * from "./registrants.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports